<template>
  <div class="col-12 g">
    <br>
    <div class="card">
        <div class="card-header">

            <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="📥 طباعة / تصدير"
            variant="primary"
            >
              <b-dropdown-item @click="printDiv()">
                  <i class="fa fa-print"></i>
                  طباعة التقرير
              </b-dropdown-item><hr>
              <b-dropdown-item @click="exportToExcel()">
                  <i class="fa fa-download"></i>
                  تصدير التقرير اكسل
              </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm table-striped" id="headerTable">
                    <thead>
                        <th>
                            م
                        </th>
                        <th>
                            الطالب
                        </th>
                        <th>
                            الفصل
                        </th>
                        <th>
                            عدد الحصص الحضور
                        </th>
                        <th>
                            عدد الحصص الغياب
                        </th>
                        <th class="dnone2">
                            تفاصيل
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="(student, index) in students">
                            <tr :key="student.name">
                                <td>
                                    {{ index+1 }}
                                </td>
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    {{ student.classtitle }}
                                </td>
                                <td>
                                    {{ student.total_1 }}
                                </td>
                                <td>
                                    {{ student.total_2 }}
                                </td>
                                <td class="dnone2">
                                    <button class="btn btn-sm btn-primary" style='border-radius: 0px;' @click="details(student)" v-b-modal.modal-m>
                                        عرض التفاصيل
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="modal-m" size="lg" title="التفاصيل" hide-footer>
         <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        اليوم
                    </th>
                    <th>
                        الحصص
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(a, index) in arr" :key="index*2">
                        <td>
                            <strong>{{ days[a.day] }}</strong>
                            &nbsp;&nbsp;
                            <small> {{ a.date }}</small>
                        </td>
                        <td>
                           <template v-for="(lesson, i) in a.lessons">
                            <button :key="i + a.name" :class="'btn btn-sm text-white ' + (lesson.status == 1 ? 'bg-success' : 'bg-danger')" style='border-radius: 0px; padding: 5px'>
                                <strong v-if="lesson.status == 1" class="fa fa-checks">√</strong>
                                <strong v-if="lesson.status == 2" class="fa fa-timess">X</strong>
                                {{ lesson.lesson }}
                            </button>
                        </template> 
                        </td>
                    </tr>
                </tbody>
            </table>
         </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
    BDropdown,
    BDropdownItem,
    },
    directives: {
    Ripple,
    "b-modal": VBModal,
    },
    data(){
        return {
            students: [],
            n_students: [],
            school_id: localStorage.getItem("school_id"),
            arr: [],
            days: {
                0: "الاحد",
                1: "الاثنين",
                2: "الثلاثاء",
                3: "الاربعاء",
                4: "الخميس",
                5: "الجمعة",
                6: "السبت"
            },
        }
    },
    created(){
        var g = this;
        var naqar_get = function(t){
        return new Promise((resolveOuter) => {
            $("body").append(`<textarea class='get-local-storage' name='${t}'></textarea>`)
            var i111 = setInterval(function(){
                if($(".get-local-storage-value[name='"+t+"']").length){
                    clearInterval(i111)
                    resolveOuter($(".get-local-storage-value[name='"+t+"']").val())
                }
            })
        });
        }
        setTimeout(() => {
            naqar_get("data").then(function(data){
                naqar_get("n_students_" + g.school_id).then(function(d){
                    if(!d || d == "null" || d == "undefined"){
                        alert("لضمان اضافة الغياب وارسال الرسائل برجاء تحديث بيانات الطلاب من نور للاداة \n من خلال الاداة في مدرستي اضغط على خدمات الطلاب واضغط على تحديث بيانات الطلاب من نور.")
                    }else{
                        g.n_students = JSON.parse(d)
                    }

                var arr = [];
                 JSON.parse(data).forEach(function(m){
                var percent = 0.5, nn = {}
                g.n_students.forEach(function(n){
                    if(g.similarity(m.name , n.name) > percent){
                        percent = g.similarity(m.name, n.name)
                        nn = n
                    }
                })
                if(nn.name){
                    m.name = nn.name
                    m.classroom = nn.classname + ' - ' + nn.classroom
                    m.phone = nn.phone
                }
                arr.push(m)
            })
            g.students = arr
                })
                })
        }, 1000)
    },methods: {
        printDiv() {
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                .dnone2{
                    display: none
                }
                button{
                    background: none;
                    border:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
        },
        exportToExcel() {
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").outerHTML +
                '</body> ' +
                '</html>'
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
        },
        editDistance: (s1, s2) => {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
            if (i == 0)
            costs[j] = j;
            else {
            if (j > 0) {
                var newValue = costs[j - 1];
                if (s1.charAt(i - 1) != s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue),
                    costs[j]) + 1;
                costs[j - 1] = lastValue;
                lastValue = newValue;
            }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    },
        similarity: function(s1, s2){
            var longer = s1;
            var shorter = s2;
            if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
            }
            var longerLength = longer.length;
            if (longerLength == 0) {
            return 1.0;
            }
            return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
        },
        details(student){
            var g = this;
            var arr = []
            for (const [date, valuex] of Object.entries(student.days)) {
                for (const [day, value] of Object.entries(valuex)) {
                    arr.push({
                        date: date,
                        day: day,
                        lessons: value
                    })
                }
            }
            g.arr = arr
        }
    }
}
</script>

<style>

</style>